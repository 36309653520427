export const SR_TABS = {
  RECENT: 'recent',
  ARCHIVED: 'archive',
  CASHBACK: 'cashback',
  COMPLAINT: 'complaint',
  CLAIM_REBATE: 'claim/rebate',
};
export const SEARCH_NO_RESULTS_ERROR = 'No Records Found.';
export const SEARCH_NETWORK_ERROR = 'System failure. Please try again.';
export const TOKEN_EXPIRED = 'Token expired. Please login again.';
export const UPDATE_SR_ERROR = 'An error occurred while updating the SR. Please try again.';
export const REFRESH_SR_ERROR =
  'An error occurred while refreshing the SR. Please refresh the SR to view correct details.';
export const CREATE_SR_ERROR = 'An error occurred while creating the SR. Please try again.';
export const CREATE_SR_CANCEL_ERROR = 'Please select the Cancellation Reason to proceed';
export const SERVICE_REQUEST_ERROR = 'Service Request not selected!';
export const CARD_DECLINE_ERROR =
  'The card details have been declined. Please correct and try again or use a different card';
export const PAYMENT_UPDATE_FAILED = 'Internal Error Occurred, Payment failed Kindly try again!';
export const INVALID_NAME_ON_CARD_ERROR =
  'Card holder name should include both first and last name';
export const CARD_NUMBER_INVALID_ERROR =
  'Credit card number length does not meet minimum allowable length';
export const CARDS_EXISTS_ERROR = 'Card already exists';
export const CARD_LINE1_NUMBER_SEQUENCE_ERROR = 'Address line1 cannot have 13 or more numbers';
export const CARD_LINE2_NUMBER_SEQUENCE_ERROR = 'Address line2 cannot have 13 or more numbers';
export const CARD_LINE3_NUMBER_SEQUENCE_ERROR = 'Address line3 cannot have 13 or more numbers';
export const CARD_CITY_NUMBER_SEQUENCE_ERROR = 'City cannot have 13 or more numbers';
export const CARD_POSTCODE_NUMBER_SEQUENCE_ERROR = 'Postal Code cannot have 13 or more numbers';
export const EXPIRY_DATE_INVALID_ERROR = 'You have entered an invalid Expiration Date';
export const EXPIRY_YEAR_INVALID_ERROR = 'You have entered an invalid Year';

export const SR_TYPES = {
  ONSALE_REGISTRATION: 'OnSale Registration',
  BOOK_CASHBACK_EVENT: 'Book Cashback Event',
  ADHOC_PAYMENT: 'Adhoc Payment',
  COMPLAINT: 'Complaint',
  BOOK_EVENT: 'Book Event',
  CINEMA_ECODE: 'Cinema eCode',
  FILM_RENTAL_ECODE: 'Film Rental eCode',
};

export const SR_STATUS = {
  OPEN: 'Open',
  PENDING: 'Pending',
  CANCELLED: 'Cancelled',
  CLOSED: 'Closed',
  APPROVED: 'Approved',
  PASSED: 'Passed',
  DENIED: 'Denied',
  SUPPLIED: 'Supplied',
  COMPLETED: 'Completed',
  CONFIRMED: 'Confirmed',
};

export const statusList = [
  'Assessing',
  'Cancelled',
  'Closed',
  'Confirmed',
  'Declined',
  'Failed - Missing PV',
  'Fee not Paid',
  'Missing Payment Vehicle',
  'Open',
  'Pending',
  'Quoted',
  'Rejected by Vendor',
  'Requested',
  'Sent to Shipper',
  'Supplied',
  'Completed',
  'SystemOpen',
  'Approved Pending Audit',
  'Denied',
  'Approved',
];

export const ulsterRIPackages = {
  data: [1043726, 1043756, 1043876, 1043916, 1043606, 1044126],
};

export const SR_ACCEPTING_PAYMENTS = [
  'Book Cashback Event',
  'OnSale Registration',
  'Complaint',
  'Adhoc Payment',
  'Concierge',
  'Cinema eCode',
  'Book Event',
  'Cinema Adhoc Payment',
  'Travel Adhoc Payment',
  'Event Booking Adhoc Payment',
  'Film Rental eCode',
  'Film Rental Adhoc Payment',
];

export const REFULFILL_CLAIMED_OFFER_TYPE = 'RE_FULFILL';
export const REDEEM_CLAIMED_OFFER_TYPE = 'REDEEM';
export const REQUEST_REFULFILL_BUTTON_TEXT = 'Request for Re-fulfill';
export const REFULFILL_BUTTON_TEXT = 'Re-fulfill';

export const TRANSACTION_REASON = {
  PURCHASE: 'Purchase',
};

export const CURRENCY_ICON = {
  GBP: '£',
  EUR: '€',
};

export const PAYMENT_METHOD = {
  BANK: 'Bank Account',
  CARD: 'Credit Card',
};

export const PAYMENT_METHOD_TYPE = {
  'Bank Account': 'bank-account',
  'Credit Card': 'credit-card',
};

export const PAYMENT_STATUS = {
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  TRANSACTION_ERROR: 'Transaction Error',
  PENDING: 'Pending',
  NEW: 'New',
  CANCELLED: 'Cancelled',
  CONFIRMED: 'Confirmed',
};

export const PAYMENT_STATUS_CAPS = {
  ACCEPTED: PAYMENT_STATUS.ACCEPTED.toUpperCase(),
  DECLINED: PAYMENT_STATUS.DECLINED.toUpperCase(),
  TRANSACTION_ERROR: PAYMENT_STATUS.TRANSACTION_ERROR.toUpperCase(),
  PENDING: PAYMENT_STATUS.PENDING.toUpperCase(),
  NEW: PAYMENT_STATUS.NEW.toUpperCase(),
  CANCELLED: PAYMENT_STATUS.CANCELLED.toUpperCase(),
  CONFIRMED: PAYMENT_STATUS.CONFIRMED.toUpperCase(),
};

export const PAYMENT_ERROR = {
  'soft-declined': PAYMENT_STATUS.DECLINED,
  'hard-declined': PAYMENT_STATUS.DECLINED,

  'external-error': PAYMENT_STATUS.TRANSACTION_ERROR,
  'external-error-final': PAYMENT_STATUS.TRANSACTION_ERROR,

  accepted: PAYMENT_STATUS.ACCEPTED,
  processed: PAYMENT_STATUS.ACCEPTED,

  'awaiting-processing': PAYMENT_STATUS.PENDING,
};

export const MASTERCARD_REGEX =
  /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
export const VISA_REGEX = /^4[0-9]{12}(?:[0-9]{3})?$/;

export const ISSUE_PAYMENT_ACTIVITY_STATUS = {
  New: 'Not Started',
  Accepted: 'Completed',
  Declined: 'Declined',
  'Transaction Error': 'Failed',
  Cancelled: 'Cancelled',
  Pending: 'Completed',
};

export const HAPPY_TO_BOOK_ACTIVITY_STATUS = {
  New: 'Requested',
  Accepted: 'Requested',
  Declined: 'Cancelled',
  'Transaction Error': 'Cancelled',
  Cancelled: 'Cancelled',
};

export const enquirySRTypes = [
  'Cashback Enquiry',
  'CLO Enquiry',
  'Coupon Enquiry',
  'General Enquiry',
  'Gifting - Agent',
];

export const linkTransactionSRTypes = [
  'Cinema Comment',
  'Cinema Query',
  'Film Rental Comments',
  'Film Rental Query',
  'Event Booking Comment',
  'Event Booking Query',
];

export const REFILL_REASONS = [
  'Lost',
  'Funds Missing',
  'Stolen',
  'Expired Card',
  'Empty Card',
  'Card Status - Activation',
];

export const agentRole = {
  level1: 'L1',
};

export const RBSG_TENANT_ID = ['1016498', '1016568', '1024226', 'internal'];

export const V2_ENABLED_CLIENTS = ['5000005', '1024226', '1016568', '1016498'];

export const SR_DASHBOARD_SEARCH_PAGE_SIZE = 301;

export const MEMBER_SEARCH_PAGE_SIZE = 20;

export const SSO_ERROR_TEXT = {
  401: "We're sorry, your account could not be authenticated. Please see your system administrator.",
  default:
    'Something went wrong. Please try again or contact your administrator if the issue persists.',
};
export const SSO_LOADING_TEXT = 'Thank you for your patience as we authenticate your account.';

export const PERSONAL_DIALOG = {
  button_text_false: 'NO',
  button_text_true: 'YES',
  description: 'Have you entered any personal data in the form?',
  heading: 'Personal Data',
};

export const QUERY_ALERT = {
  heading: 'Alert',
  subtitle: 'Please fill all the fields before submitting the query.',
};

export const QUERY_TICKET_ALERT = {
  heading: 'Alert',
  subtitle: 'Ticket Number not found.',
  title: 'Wrong Ticket N Alert',
};
