import {
  // BackButtonWText,
  CustomDatePicker,
  CustomTextInput,
  InfiniteScrollerCustom,
  Loading,
  NoResults,
  SnackBar,
  //TableItemCard,
  TabHeading,
  Wrapper,
} from 'Components';
import React, {Fragment, useEffect, useState} from 'react';
import {TRANSACTIONSLOG} from './transactionLogConstants';
import RefreshButton from 'Components/RefeshButton';
import {useCSContext} from 'Context/ContentStackContext';
import {
  Box,
  // Button,
  Card,
  CardActionArea,
  // CardContent,
  // CardMedia,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {useInfiniteScroll, useSnackBar} from 'Hooks';
import globalSpacing from 'Theme/Spacing';
import {useDispatch, useSelector} from 'react-redux';
import {format, isDate} from 'date-fns';
import {DATE_FORMAT} from 'Utils/constants/DateFormat';
import {useLocation} from 'react-router-dom';
import {getAllClaimedOffer} from 'Redux/Slices/transactionDashboardList';
import TransactionDetails from './transactionDetails';

const TabTransactionLogs = () => {
  const {artifacts} = useCSContext();
  const theme = useTheme();
  const location: any = useLocation();
  const state = location?.state;
  const [deatilView, setDetailView] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<any>({});
  const [transactions, setTransactions] = useState<any[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<any>({});
  const loginDetails = useSelector((state: any) => state?.login);
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const dispatch = useDispatch();
  const {txnOfferTypes} = useSelector((state: any) => state?.login?.tenantConfig);

  const txn = ['Select All', ...txnOfferTypes.split(',').map((offer) => offer.trim())];
  const [filterByType, setFilterByType] = useState(txn[0]);
  const [filterByDate, setFilterByDate] = useState(null);
  const [error, setError] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [{pageNo, nextPage, resetPage, pageSize}] = useInfiniteScroll();
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);

  const [loading, setLoading] = useState(true);
  const [refreshFetch, setRefreshFetch] = useState(false);
  const allowedOfferTypes = [
    'instantwin',
    'reward',
    'prizedraw',
    'giftcard',
    'voucher',
    'cashback',
    'travel',
    'cardlinked',
  ];
  // const [error, setError] = useState(null);
  // const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  // const [transactions, setTransactions] = useState<any[]>([]);
  //const [{resetPage}] = useInfiniteScroll();
  const transactionList = useSelector(
    (state: any) => state?.transactionLogList?.transactionslogList,
  );
  //console.log(transactionList);
  //methods used in useeffect
  const _showLoading = () => pageNo === 0 && setLoading(true);
  const _handleResponse = (data, loading, error) => {
    setLoading(loading);
    if (error) {
      setError(error.message);
    } else {
      if (data) {
        const {pagingInfo, claimedOffers} = data;
        pageNo === 0 && setTotalResults(pagingInfo?.totalResults);
        claimedOffers && pageNo === 0
          ? setTransactions(claimedOffers)
          : setTransactions([...transactions, ...claimedOffers]);
      }
    }
  };

  //console.log(state?.selectedOffer?.id);
  useEffect(() => {
    if (state?.selectedClaim?.id) {
      // handleSRRowClick();
      // showTransactionDetails(state?.selectedClaim);
      setDetailView(true);
      setSelectedTransaction(state?.selectedClaim);
      state.selectedClaim = null;
    }
    if (state?.selectedOffer?.id) {
      setDetailView(true);
      setSelectedOffer(state?.selectedOffer); // Set the selected offer
      state.selectedOffer = null; // Reset the selectedOffer in state
    }
  }, [state]);

  // const transactionList = [];
  useEffect(() => {
    if (refreshFetch) {
      (async () => {
        /*setLoading(true);
        let payload = {
          tenantId: loginDetails?.tenantID,
          userId: selectedMember?.currentMemberDetails?.userId,
          customerId:
            selectedMember?.currentMemberDetails?.memberId ??
            selectedMember?.currentMemberDetails?.userId,
          userGroupId: selectedMember?.membershipId,
          pageNumber: 0,
          pageSize: 100,
        };
        await dispatch(getAllClaimedOffer(payload)).unwrap();
        setRefreshFetch(false);
        setLoading(false);*/
        const {data, loading, error} = await dispatch(
          getAllClaimedOffer({
            tenantId: loginDetails?.tenantID,
            userId: selectedMember?.currentMemberDetails?.userId,
            customerId:
              selectedMember?.currentMemberDetails?.memberId ??
              selectedMember?.currentMemberDetails?.userId,
            userGroupId: selectedMember?.membershipId,
            pageSize: pageSize,
            pageNumber: pageNo,
          }),
        ).unwrap();
        _handleResponse(data?.getAllClaimedOffer?.[0], loading, error);
        setRefreshFetch(false);
      })();
    }
  }, [refreshFetch, pageNo, pageSize]);

  const refresh = () => {
    setRefreshFetch(true);
    resetPage();
    setFilterByType(txn[0]);
    setFilterByDate(null);
  };

  const renderLogCard = () => {
    if (transactions?.length) {
      return transactions?.map((item: any, index: number) => {
        // Find the corresponding offer in the `offers` array
        const selectedOffer = transactionList?.offers?.find(
          (offer: any) => offer.id === item.offerId,
        );

        // Helper function to determine the title
        const getCardTitle = (item: any, selectedOffer: any) => {
          const offerType = item?.offerType?.toLowerCase()?.trim();
          console.log(offerType);
          if (allowedOfferTypes.includes(offerType)) {
            return selectedOffer?.title;
          }
          /*else if (offerType === 'event') {
            return (
              item?.data?.claimDetails?.eventName?.trim() ||
              item?.data?.claimDetails?.orderData?.data?.orderId
            );
          }*/
          return (
            item?.data?.claimDetails?.eventName?.trim() ||
            item?.data?.claimDetails?.orderData?.data?.orderId
          );
        };
        console.log(selectedOffer, item?.data?.claimDetails?.eventName?.trim());
        return (
          <Card sx={{border: 1, borderColor: theme.palette.grey.A200, mt: globalSpacing.s}}>
            <CardActionArea
              sx={{padding: globalSpacing.sm}}
              onClick={() => {
                setDetailView(true);
                setSelectedTransaction(item);
                setSelectedOffer(selectedOffer); // Set the corresponding offer
              }}
              disableRipple

              // disabled={disabled}
            >
              <Typography
                color="primary"
                variant="h4"
                sx={{
                  // maxWidth: '40%',
                  wordBreak: 'break-word',
                  marginBottom: globalSpacing.xxs,
                }}>
                {getCardTitle(item, selectedOffer)}
                {/*item?.offerType?.toLowerCase()?.trim() === 'event'
                ? item?.data?.claimDetails?.eventName?.trim()
                  ? item?.data?.claimDetails?.eventName
                  : item?.data?.claimDetails?.orderData?.data?.orderId
                : item?.data?.claimDetails?.orderData?.data?.orderId*/}
              </Typography>

              <Box flexDirection="row" display="flex" gap="5%">
                {/* claim and expiry */}
                <Box
                  flexDirection="column"
                  display={'flex'}
                  width="40%"
                  justifyContent="space-between">
                  <Typography variant="h6" component="h5">
                    <strong>{TRANSACTIONSLOG.card_field_map.claimDate} </strong>

                    {item.claimDateTime &&
                      isDate(new Date(item.claimDateTime)) &&
                      format(new Date(item.claimDateTime), DATE_FORMAT.dateFormat)}
                  </Typography>
                  {/* TODO this field might change name in backend to offerenddate for transactions*/}

                  <Typography variant="h6" component="h5">
                    <strong>{TRANSACTIONSLOG.card_field_map.expiryDate} </strong>
                    {item.expiryDateTime &&
                      isDate(new Date(item.expiryDateTime)) &&
                      format(new Date(item.expiryDateTime), DATE_FORMAT.dateFormat)}
                  </Typography>
                </Box>
                {/* vendor name */}
                <Box flexDirection="column" display={'flex'} width="20%">
                  <Typography variant="subtitle1">
                    {allowedOfferTypes.includes(item?.offerType?.toLowerCase()?.trim())
                      ? selectedOffer?.merchant?.name
                      : item?.data?.claimDetails?.merchantName}
                  </Typography>
                </Box>

                {/* type and status */}
                <Box flexDirection="column" display={'flex'}>
                  <Typography variant="h6" component="h5">
                    {TRANSACTIONSLOG.card_field_map.type}{' '}
                    <strong style={{textTransform: 'capitalize'}}>{item.offerType}</strong>
                  </Typography>

                  <Typography variant="h6" component="h5">
                    {TRANSACTIONSLOG.card_field_map.status}{' '}
                    <strong style={{textTransform: 'capitalize'}}>{item.status}</strong>
                    {/* {item.status} */}
                  </Typography>
                </Box>
              </Box>
            </CardActionArea>
          </Card>
        );
      });
    } else {
      return <NoResults text={TRANSACTIONSLOG.no_results_text} />;
    }
  };

  useEffect(() => {
    _showLoading();
    if (!filterByDate) {
      if (filterByType === txn[0]) {
        (async () => {
          /*setLoading(true);
          let payload = {
            tenantId: loginDetails?.tenantID,
            userId: selectedMember?.currentMemberDetails?.userId,
            customerId:
              selectedMember?.currentMemberDetails?.memberId ??
              selectedMember?.currentMemberDetails?.userId,
            userGroupId: selectedMember?.membershipId,
            pageNumber: 0,
            pageSize: 100,
          };
          await dispatch(getAllClaimedOffer(payload)).unwrap();
          setLoading(false);*/
          const {data, loading, error} = await dispatch(
            getAllClaimedOffer({
              tenantId: loginDetails?.tenantID,
              userId: selectedMember?.currentMemberDetails?.userId,
              customerId:
                selectedMember?.currentMemberDetails?.memberId ??
                selectedMember?.currentMemberDetails?.userId,
              userGroupId: selectedMember?.membershipId,
              pageSize: pageSize,
              pageNumber: pageNo,
            }),
          ).unwrap();
          _handleResponse(data?.getAllClaimedOffer?.[0], loading, error);
        })();
      } else {
        //if filter != show all
        (async () => {
          /*setLoading(true);
          let payload = {
            tenantId: loginDetails?.tenantID,
            userId: selectedMember?.currentMemberDetails?.userId,
            customerId:
              selectedMember?.currentMemberDetails?.memberId ??
              selectedMember?.currentMemberDetails?.userId,
            userGroupId: selectedMember?.membershipId,
            offerType: filterByType,
            pageNumber: 0,
            pageSize: 100,
          };
          await dispatch(getAllClaimedOffer(payload)).unwrap();
          setLoading(false);*/
          const {data, loading, error} = await dispatch(
            getAllClaimedOffer({
              tenantId: loginDetails?.tenantID,
              userId: selectedMember?.currentMemberDetails?.userId,
              customerId:
                selectedMember?.currentMemberDetails?.memberId ??
                selectedMember?.currentMemberDetails?.userId,
              userGroupId: selectedMember?.membershipId,
              offerType: filterByType,
              pageSize: pageSize,
              pageNumber: pageNo,
            }),
          ).unwrap();
          _handleResponse(data?.getAllClaimedOffer?.[0], loading, error);
        })();
      }
    } else {
      //Filter by date only (type = show all)
      if (filterByDate && filterByType === txn[0]) {
        //filterByDate (it's already formatted in datepicker onchange)
        (async () => {
          /*setLoading(true);
          let payload = {
            tenantId: loginDetails?.tenantID,
            userId: selectedMember?.currentMemberDetails?.userId,
            customerId:
              selectedMember?.currentMemberDetails?.memberId ??
              selectedMember?.currentMemberDetails?.userId,
            userGroupId: selectedMember?.membershipId,
            transactionDate: format(new Date(filterByDate), 'yyyy-MM-dd'),
            pageNumber: 0,
            pageSize: 100,
          };
          await dispatch(getAllClaimedOffer(payload)).unwrap();
          setLoading(false);*/
          const {data, loading, error} = await dispatch(
            getAllClaimedOffer({
              tenantId: loginDetails?.tenantID,
              userId: selectedMember?.currentMemberDetails?.userId,
              customerId:
                selectedMember?.currentMemberDetails?.memberId ??
                selectedMember?.currentMemberDetails?.userId,
              userGroupId: selectedMember?.membershipId,
              transactionDate: format(new Date(filterByDate), 'yyyy-MM-dd'),
              pageSize: pageSize,
              pageNumber: pageNo,
            }),
          ).unwrap();
          _handleResponse(data?.getAllClaimedOffer?.[0], loading, error);
        })();
      }
      if (filterByDate && filterByType !== txn[0]) {
        //filterByDate(it's already formatted in datepicker onchange)
        (async () => {
          /*setLoading(true);
          let payload = {
            tenantId: loginDetails?.tenantID,
            userId: selectedMember?.currentMemberDetails?.userId,
            customerId:
              selectedMember?.currentMemberDetails?.memberId ??
              selectedMember?.currentMemberDetails?.userId,
            userGroupId: selectedMember?.membershipId,
            transactionDate: format(new Date(filterByDate), 'yyyy-MM-dd'),
            offerType: filterByType,
            pageNumber: 0,
            pageSize: 100,
          };
          await dispatch(getAllClaimedOffer(payload)).unwrap();
          setLoading(false);*/
          const {data, loading, error} = await dispatch(
            getAllClaimedOffer({
              tenantId: loginDetails?.tenantID,
              userId: selectedMember?.currentMemberDetails?.userId,
              customerId:
                selectedMember?.currentMemberDetails?.memberId ??
                selectedMember?.currentMemberDetails?.userId,
              userGroupId: selectedMember?.membershipId,
              transactionDate: format(new Date(filterByDate), 'yyyy-MM-dd'),
              offerType: filterByType,
              pageSize: pageSize,
              pageNumber: pageNo,
            }),
          ).unwrap();
          _handleResponse(data?.getAllClaimedOffer?.[0], loading, error);
        })();
      }
    }
  }, [filterByType, filterByDate, selectedMember?.currentMemberDetails, pageNo, pageSize]);

  return (
    <>
      <Wrapper customStyle={{width: '95%'}}>
        {!deatilView ? (
          <Fragment>
            <TabHeading
              data={TRANSACTIONSLOG}
              addOn={<RefreshButton image={artifacts.refresh} refresh={refresh} />}
            />
            <Box
              sx={{
                marginTop: globalSpacing.sm,
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <CustomTextInput
                // disabled={filterDisabled}
                inline
                label={TRANSACTIONSLOG.filter_label}
                name="filter"
                filterMenu={txn}
                value={filterByType}
                onChange={(e) => {
                  setFilterByType(e.target.value);
                  resetPage();
                }}
              />
              <CustomDatePicker
                date={filterByDate}
                handleChange={(searchDate) => {
                  setFilterByDate(searchDate);
                  resetPage();
                }}
                label={TRANSACTIONSLOG.date_search_label}
                flex={0.3}
              />
            </Box>

            {/* FIlter option  */}

            {loading ? <Loading isOpen={loading} /> : ''}

            <Box overflow="overflow">
              {!loading ? (
                error ? (
                  <SnackBar
                    errorMessage={error}
                    isSnackBarOpen={isSnackBarOpen}
                    closeSnackBar={closeSnackBar}
                  />
                ) : transactions.length ? (
                  <InfiniteScrollerCustom
                    dataLength={transactions.length}
                    next={() => nextPage()}
                    hasMore={transactions.length < totalResults}>
                    <Stack
                      spacing={globalSpacing.s}
                      mt={globalSpacing.sm}
                      boxShadow="none"
                      tabIndex={0}>
                      {renderLogCard()}
                    </Stack>
                  </InfiniteScrollerCustom>
                ) : (
                  <NoResults text={TRANSACTIONSLOG.no_results_text} />
                )
              ) : null}

              <Box>{/*renderLogCard()*/}</Box>
            </Box>
          </Fragment>
        ) : (
          <>
            <TransactionDetails
              selectedTransaction={selectedTransaction}
              selectedOffer={selectedOffer}
              toggleView={() => {
                setDetailView(false);
              }}
            />
          </>
        )}
      </Wrapper>
    </>
  );
};

export default TabTransactionLogs;
